































































































































































import { Component, Vue } from "vue-property-decorator";
import OrderPane from "@/components/order/order-pane.vue";
import DatePicker from "@/components/date-picker.vue";
import { apigroupBuyingLists, apiOtherLists } from "@/api/order/order";
import ExportData from "@/components/export-data/index.vue";
import { RequestPaging } from "@/utils/util";
import { OrderType } from "@/utils/type";
@Component({
  components: {
    OrderPane,
    DatePicker,
    ExportData,
  },
})
export default class Order extends Vue {
  // S Data
  activeName: any = "all_count"; //全部;

  apigroupBuyingLists = apigroupBuyingLists;

  tabs = [
    {
      label: "全部",
      name: OrderType[0],
    },
    {
      label: "待付款",
      name: OrderType[1],
    },
    {
      label: "待发货",
      name: OrderType[2],
    },
    {
      label: "待收货",
      name: OrderType[3],
    },
    {
      label: "已完成",
      name: OrderType[4],
    },
    {
      label: "已关闭",
      name: OrderType[5],
    },
  ];

  index = 0;

  pager = new RequestPaging();

  tabCount = {
    all_count: 0, //全部
    pay_count: 0, //待支付
    delivery_count: 0, //待收货
    receive_count: 0, //待发货
    finish_count: 0, //已完成
    close_count: 0, //已关闭
  };

  form = {
    order_sn: "", //否	string	订单信息
    user_info: "", //否	string	用户信息
    goods_name: "", //否	string	商品名称
    contact_info: "", //否	string	收货人信息
    order_terminal: "", //否	int	订单来源;1-微信小程序;2-微信公众号;3-手机H5;4-PC;5-苹果app;6-安卓app;
    order_type_admin: "", //否	int	订单类型;0-普通订单;1-拼团订单;2-秒杀订单;3-砍价订单
    pay_way: "", //否	int	支付方式:1-余额支付;2-微信支付;3-支付宝支付;
    pay_status: "", //否	int	支付状态;0-待支付;1-已支付;
    delivery_type: "", //否	int	配送方式;1-快递发货;2-上门自提;3-同城配送
    time_type: "", //否	string	时间类型:create_time-下单时间;pay_time-支付时间
    start_time: "", //否	string	开始时间
    end_time: "", //否	string	结束时间
  };

  otherLists: any = {
    order_terminal_lists: [], //来源
    order_type_lists: [], //订单类型
    pay_way_lists: [], //余额支付
    pay_status_lists: [], //支付状态
    delivery_type_lists: [], //配送方式
  };
  // E Data

  // S Methods

  // 获取订单信息
  getOrderLists(page?: number) {
    page && (this.pager.page = page);
    if (this.form.start_time != "" || this.form.end_time != "") {
      if (this.form.time_type == "") {
        return this.$message.error("选择时间必须选择时间类型!");
      }
    }

    let status: any =
      this.activeName == "all_count" ? "" : OrderType[this.activeName];
    this.pager
      .request({
        callback: apigroupBuyingLists,
        params: {
          order_status: status == "" ? "" : status - 1,
          ...this.form,
        },
      })
      .then((res) => {
        this.tabCount = res?.extend;
      });
  }

  // 重置搜索
  reset() {
    Object.keys(this.form).map((key) => {
      this.$set(this.form, key, "");
    });
    this.getOrderLists();
  }

  // 获取订单其他 方式数据
  getOtherMethodList() {
    apiOtherLists().then((res: any) => {
      this.otherLists = res;
    });
  }
  // E Methods

  // S  life cycle

  created() {
    // 获取订单信息
    this.getOrderLists();
    // 获取其他方式数据
    this.getOtherMethodList();
  }

  // E life cycle
}
